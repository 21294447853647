import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import MenuProductCard from '../components/MenuProductCard';
import { updateShopData, itemsMenuNews, itemsMenu} from '../app/shopReducer';
import Divider from '../components/Divider';
import { useWindowSize } from '../utils';

const useStyles = makeStyles((theme) => ({
	heroTitle: {
		padding: theme.spacing(3, 0),
	},
	container: {
		padding: theme.spacing(0, 3),
	},
	title: {
		padding: theme.spacing(6, 0),
		flexGrow: 1,
		textAlign: 'center',
		fontWeight: 700
	},
	separator: {
		display: 'flex', alignItems: 'center'
	},
	separatorLine: {
		flex: 1,  borderBottom: '2px solid', borderBottomColor: theme.palette.primary.main,
	},
	main: {
		backgroundColor: 'rgba(236, 201, 138, 1)',
		minHeight: '100vh',
		padding: theme.spacing(0, 6),
	},
	separatorTitle: {
		color: theme.palette.primary.main,
		border: '2px solid',
		borderColor: theme.palette.primary.main,
		borderRadius: '4px',
		fontWeight: 700,
		fontSize: '20px',
		padding: theme.spacing(0.5, 2),
	},
	itemsContainer: {
		padding: theme.spacing(2, 0),
	}
}));

const TIMEOUT = 120000;

export default function Items() {
	let { UUID } = useParams();
	const { t } = useTranslation();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const itemsNew = useSelector(itemsMenuNews)
	const items = useSelector(itemsMenu)
	

	useEffect(() => {
		let timer = setTimeout(() => {
			window.location.reload()
		}, TIMEOUT);

		const procrastinateTimer = event => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				window.location.reload()
			}, TIMEOUT);
		};

		window.addEventListener('touchstart', procrastinateTimer);
		window.addEventListener('scroll', procrastinateTimer);

		return () => {
			window.removeEventListener('touchstart', procrastinateTimer);
			window.removeEventListener('scroll', procrastinateTimer);
			clearTimeout(timer);
		};
	});

	useEffect(() => {
		fetch(`${process.env.REACT_APP_WAREHOUSE_API}/b2c/menu/uuid/${UUID}`)
		.then(res => res.json())
		.then(result => {
			if(result.statusCode && result.statusCode !== 200) {
				setLoading(false);
				setError(result.statusCode);
				return;
			}
			setLoading(false);
			dispatch(updateShopData({result, UUID}));
		},
		error => {
			setLoading(false);
			setError(500);
		}
		);
	}, [UUID, dispatch, error]);
	
	const classes = useStyles();
	const size = useWindowSize();
	
	const horizzontal = size.width > size.height

	let showItemsNew = !loading && !error && itemsNew.length > 0;
	let showItems = !loading && !error && items.length > 0;
	
	if(showItems && horizzontal && itemsNew.length > 6) {
		showItems = false;
	}
	if(showItems && !horizzontal && itemsNew.length > 6) {
		showItems = false;
	}

	return (
		<main className={classes.main}>
			<CssBaseline />
			<Typography className={classes.title} variant="h3" noWrap>FOORBAN</Typography>

			{showItemsNew && <Divider title={`${t("NOVITA'")}`}></Divider>}
			{showItemsNew && <div className={classes.itemsContainer}><Grid container justify="center" alignItems="center" spacing={4}>
				{itemsNew
					.filter((item, itemKey) => {
						if(horizzontal)
							return true;

						return itemKey < 8;
					})
					.sort((a, b) => {
						if (a.displayOrder < b.displayOrder) {
							return -1;
						}
						if (b.displayOrder < a.displayOrder) {
							return 1;
						}
						return 0;
					})
					.map((item) => {
						return (
							<MenuProductCard key={item.id} item={item} />
						)
					})
				}
				{!showItems && <MenuProductCard onlyTitle={true} item={{
					link: `${process.env.REACT_APP_WAREHOUSE_B2C}/menu/${UUID}`,
					label: 'Scopri il menu completo'
				}} />}
			</Grid></div>}
			
			{showItems && <Divider></Divider>}
			{showItems && <div className={classes.itemsContainer}><Grid justify="center" alignItems="center" container spacing={4}>
				{items
					.filter((item, itemKey) => {
						if(horizzontal)
							return true;

						return itemKey < 8;
					})
					.sort((a, b) => {
						if (a.displayOrder < b.displayOrder) {
							return -1;
						}
						if (b.displayOrder < a.displayOrder) {
							return 1;
						}
						return 0;
					})
					.map((item) => {
						return (
							<MenuProductCard key={item.id} item={item} />
						)
					})
				}
				<MenuProductCard onlyTitle={true} item={{
					link: `${process.env.REACT_APP_WAREHOUSE_B2C}/menu/${UUID}`,
					label: 'Scopri il menu completo'
				}} />
			</Grid></div>}
		</main>
	);
}